import type { Lucid } from '../types/types';

const environmentOptions = ['development', 'test', 'production', 'preprod'];

const isValidEnv = (value?: string): value is Lucid.Environment.Keys =>
  value ? environmentOptions.includes(value) : false;

/** @returns the value from process.env.NODE_ENV */
const getEnv = (): Lucid.Environment.Keys => {
  const currentEnv = process.env.NODE_ENV;
  const currentActiveEnv = process.env.GATSBY_NODE_ENV;

  if (!isValidEnv(currentEnv)) return 'development';

  if (isValidEnv(currentActiveEnv)) return currentActiveEnv;

  return currentEnv;
};

export const isDev = () => 'development' === getEnv();
export const isTest = () => 'test' === getEnv();
export const isProd = () => 'production' === getEnv();
export const isPreProd = () => 'preprod' === getEnv();

export default getEnv;
